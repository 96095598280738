import React from 'react'
import Image from '../components/image'
import Badge from '../components/badge'

const ExperienceDetails = ({
  image,
  title,
  subtitle,
  experience,
  points,
  text,
  totalPoints,
  label,
  onClick,
  redeemed,
  data,
}) => (
  <>
    <Image src={image} alt={title} classes="animated fadeIn experience-image" />
    <div className="animated fadeInUp">
      {redeemed && (
        <div className="experience-wrapper light-bg">
          <Badge points={data.label} variant="inline" />
          <p dangerouslySetInnerHTML={{ __html: data.message }} />
        </div>
      )}
      <div className="experience-wrapper">
        {!redeemed && <Badge points={`${points} PTS`} variant="inline" />}
        <h1 className="white-text">{title}</h1>
        <h3 className="color6-text">{subtitle}</h3>
        {!redeemed && (
          <button
            disabled={totalPoints < points}
            onClick={onClick}
            className="btn btn-primary"
          >
            {label}
          </button>
        )}
        <div className="spacer"></div>
        {text.split('\n').map((item, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: item }}></p>
        ))}

        <div className="spacer"></div>
      </div>
    </div>
  </>
)

export default ExperienceDetails

import React, { Component } from 'react'
import Data from '../../data/customerLoyalty'
import Menu from '../../common/menu'
import Header from '../../common/header'
import Footer from '../../common/footer'
import Card from '../../components/card'
import ModalRedeem from '../../components/modalRedeem'

class CustomLoyaltyExperiences extends Component {
  state = {
    isValid: false,
    isError: false,
    totalPoints: 30,
    newPoints: 0,
    code: null,
    showModal: false,
    isRedeemPoints: false,
    redeemFormatCount: 6,
  }

  componentDidMount() {
    window.scroll(0, 0)
    this.checkRedeemFormat(window.location.search)
  }

  handleOpenModal() {
    window.scroll(0, 0)
    document.body.classList.add('no-scroll')
    this.setState({
      showModal: true,
    })
  }

  handleCloseModal() {
    document.body.classList.remove('no-scroll')
    this.setState({
      showModal: false,
    })
  }

  handleCodeInput = vals => {
    const { redeemFormatCount } = this.state
    const lng = vals.length === redeemFormatCount ? true : false
    if (lng) {
      this.setState({
        isValid: true,
        code: vals,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { code, redeemFormatCount } = this.state
    const urlParams = new URLSearchParams(window.location.search)
    const uid = urlParams.has('uid') ? urlParams.get('uid') : null

    // Check if its in the array and matches url {QR code vs RFID}
    let check
    if (redeemFormatCount === 6) {
      if (Data.qr_codes.tokens.includes(code)) {
        check = [{ points: Data.qr_codes.points }]
      } else {
        check = []
      }
    } else {
      check = Data.nfc_codes.filter(
        f => f.uid === uid && f.code === code.toLowerCase()
      )
    }

    if (check.length >= 1) {
      const newPoints = check[0].points
      let totalPoints = this.props.totalPoints + newPoints

      // Update the parent scope
      this.props.onUpdateTotalPoints(totalPoints)

      // Valid code
      this.setState({
        isSubmitted: true,
        isValid: true,
        isRedeemPoints: true,
        newPoints: newPoints,
      })

      setTimeout(e => {
        this.handleCloseModal()
      }, 4000)
    } else {
      // Invalid code
      this.setState({
        isSubmitted: true,
        isError: true,
        code: null,
      })
    }
  }

  checkRedeemFormat = uid => {
    let count
    if (uid.split('?uid=')[1] === 'null') {
      count = 6
    } else {
      count = 4
    }
    this.setState({ redeemFormatCount: count })
  }

  render() {
    const {
      showModal,
      newPoints,
      isValid,
      isError,
      isRedeemPoints,
      redeemFormatCount,
    } = this.state
    const { experiences, totalPoints, pointsRedeemed } = this.props
    const uid = window.location.search

    return (
      <>
        <section className="fullscreen gradient-bg">
          <Menu
            uid={uid}
            page={1}
            totalPoints={totalPoints}
            pointsRedeemed={pointsRedeemed}
          />
          <Header totalPoints={totalPoints} />
          <div className="cards container">
            <div className="center-text page-title">Rewards</div>
            <button
              onClick={this.handleOpenModal.bind(this)}
              className="btn btn-primary"
            >
              {Data.experiences.label}
            </button>
            <p className="center-text">{Data.experiences.text}</p>
            <div className="spacer-sm"></div>
            {experiences.map((card, i) => (
              <Card key={i} index={i} uid={uid} {...card} />
            ))}
          </div>
        </section>
        <Footer />
        {showModal && (
          <ModalRedeem
            redeemFormatCount={redeemFormatCount}
            isRedeemPoints={isRedeemPoints}
            isValid={isValid}
            isError={isError}
            onClose={this.handleCloseModal.bind(this)}
            onChange={this.handleCodeInput.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            newPoints={newPoints}
            data={Data.modalRedeem}
          />
        )}
      </>
    )
  }
}

export default CustomLoyaltyExperiences

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MenuBtn from '../components/menuBtn'

const Menu = ({ uid, page, totalPoints, pointsRedeemed }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <div>
      <MenuBtn
        open={!menuOpen}
        handleClick={setMenuOpen}
        totalPoints={totalPoints}
        pointsRedeemed={pointsRedeemed}
      />
      <div
        className={`menu-panel ${
          menuOpen ? 'menu-panel-open' : 'menu-panel-closed'
        }`}
      >
        <ul>
          <li
            onClick={() => setMenuOpen(false)}
            className={page === 0 ? 'menu-link-active' : ''}
          >
            <Link
              to={{
                pathname: '/provenance',
                search: uid,
              }}
              className="menu-link"
            >
              Bottle Story
            </Link>
          </li>
          <li
            onClick={() => setMenuOpen(false)}
            className={page === 1 ? 'menu-link-active' : ''}
          >
            <Link
              to={{
                pathname: '/customer-loyalty/experiences',
                search: uid,
              }}
              className={`menu-link ${
                totalPoints > 30 || pointsRedeemed ? '' : 'points-available'
              }`}
            >
              Rewards
            </Link>
            {totalPoints > 30 || pointsRedeemed ? null : (
              <div className="menu-link-points">Points Available</div>
            )}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Menu

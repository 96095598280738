import React from 'react'

const Line = ({ first, last }) => (
  <div
    className={`timeline-line ${first ? 'first-line' : ''} ${
      last ? 'last-line' : ''
    }`}
  />
)

export default Line

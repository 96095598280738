import React from 'react'
import TimelineLabel from '../components/timelineLabel'
import TimelineDate from '../components/timelineDate'
import TimelineButton from '../components/timelineButton'
import Map from '../components/map'

const TimelineItem = ({
  icon,
  label,
  date,
  button,
  styles,
  type,
  coordinates,
  path,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const uid = urlParams.has('uid') ? urlParams.get('uid') : null
  return (
    <div className={`${styles} timeline-detail`}>
      {icon !== undefined && label !== undefined && (
        <TimelineLabel icon={icon} label={label} />
      )}
      {date !== undefined && <TimelineDate date={date} />}

      {type === 'map' && (
        <div className="timeline-map">
          <Map zoom={14} coordinates={coordinates} />
        </div>
      )}
      {type === 'button' && (
        <TimelineButton label={label} path={`${path}?uid=${uid}`} />
      )}
    </div>
  )
}

export default TimelineItem

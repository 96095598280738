import React from 'react'
import Logo from '../common/logo'
import ProgressBlue from '../components/progressBlue'

const Loading = ({ data }) => (
  <>
    <section className="fullscreen gradient-bg instant-win-loader">
      <Logo size="lg" classes="animated fadeIn" />
      <div className="center-text login-box animated fadeIn">
        <h1 className="color1-text animated fadeIn">{data.title}</h1>
        <h3
          className="animated fadeIn"
          dangerouslySetInnerHTML={{ __html: data.subtitle }}
        />
        <ProgressBlue delay={1000} variant="md" />
      </div>
    </section>
  </>
)

export default Loading

import React from 'react'
import Bottle from '../../common/bottle'
import TimelineLabel from '../../components/timelineLabel'

const ProvenanceLoading = ({ data }) => (
  <>
    <section className="fillscreen gradient-bg provenance-loader">
      <Bottle classes="provenance-bottle-img bottle-img-overlay animated fadeInUp" />
      <div className="intro-text-wrapper center-text text-box animated fadeIn">
        <div className="intro-text">
          <h3 className="white-text">{data.subtitle}</h3>
          <h1
            className="color1-text animated fadeIn"
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <p className="color1-text animated fadeIn">{data.standfirst}</p>
        </div>
        <ul>
          {data.items.map((e, index) => (
            <li key={index}>
              <TimelineLabel icon={e.icon} label={e.label} />
            </li>
          ))}
        </ul>
      </div>
    </section>
    <h3 className="center-text">{data.leadin}</h3>
    <div className="spacer"></div>
  </>
)

export default ProvenanceLoading

import React, { Component } from 'react'
import History from '../common/history'
import Data from '../data/customerLoyalty'
import Loading from '../components/loading'

class Holding extends Component {
  componentDidMount() {
    window.scroll(0, 0)

    const urlParams = new URLSearchParams(window.location.search)
    const uid = urlParams.has('uid') ? urlParams.get('uid') : null

    setTimeout(f => {
      History.push(`/provenance?uid=${uid}`)
    }, 2300)
  }

  render() {
    return <Loading data={Data.loading} />
  }
}

export default Holding

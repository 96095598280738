import React, { Component } from 'react'
import History from '../../common/history'
import Data from '../../data/instantWin'
import Footer from '../../common/footer'
import InstantWinHeader from '../../components/instantWinHeader'
import PinInput from '../../components/pinInput'

class InstantWinForm extends Component {
  state = {
    isLoaded: false,
    isSubmitted: false,
    isError: false,
    isValid: false,
    code: null,
  }

  componentDidMount() {
    window.scroll(0, 0)

    // Fix React Input page transition positioning bug
    setTimeout(e => {
      this.setState({
        isLoaded: true,
      })
    }, 100)
  }

  handleOnCodeInput = vals => {
    const lng = vals.length === 4 ? true : false
    if (lng) {
      this.setState({
        isValid: true,
        code: vals,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { code } = this.state
    const urlParams = new URLSearchParams(window.location.search)
    const uid = urlParams.has('uid') ? urlParams.get('uid') : null

    // Check if its in the array and matches url
    const check = Data.codes.filter(
      f => f.uid === uid && f.code === code.toLowerCase()
    )

    if (check.length >= 1) {
      // Valid code
      this.setState({
        isSubmitted: true,
        isValid: true,
      })

      if (check[0].win) {
        // Redirect to Winner page
        History.push('/instant-win/win')
      } else {
        // Redirect to loser page
        History.push('/instant-win/lose')
      }
    } else {
      // Invalid code
      this.setState({
        isSubmitted: true,
        isError: true,
        code: null,
      })

      setTimeout(f => {
        document
          .getElementById('form')
          .scrollIntoView({ block: 'start', behavior: 'smooth' })
      }, 300)
    }
  }

  render() {
    const { isError, isValid, isLoaded } = this.state

    return (
      <>
        <section className="fullscreen gradient-bg">
          <InstantWinHeader
            data={Data.form}
            showBottle="true"
            classes="column"
          />
          <div className="container animated fadeIn">
            <form
              id="form"
              onSubmit={this.handleSubmit}
              className="instant-win-form"
            >
              {isLoaded && (
                <PinInput
                  isValid={isValid}
                  isError={isError}
                  onChange={this.handleOnCodeInput}
                  data={Data.form}
                />
              )}
            </form>
          </div>
        </section>
        <Footer />
      </>
    )
  }
}

export default InstantWinForm

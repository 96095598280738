import React from 'react'
import { Link } from 'react-router-dom'
import CardFooter from '../components/cardFooter'
import Badge from '../components/badge'

const Card = ({
  title,
  subtitle,
  slug,
  image,
  points,
  redeemed,
  index,
  uid,
  onClick,
}) => (
  <Link
    to={{
      pathname: `experience/${slug}`,
      search: uid,
      state: { ref: 'forward' },
    }}
    style={{ animationDelay: `${index * 200}ms` }}
    className="card animated fadeInLeft"
  >
    {redeemed ? (
      <Badge points="Redeemed" variant="overlay" />
    ) : (
      <Badge points={`${points} PTS`} variant="overlay" />
    )}
    <div
      className="card-image"
      style={{ backgroundImage: `url(/assets/images/${image})` }}
    ></div>
    <CardFooter title={title} subtitle={subtitle} />
  </Link>
)

export default Card

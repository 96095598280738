import React from 'react'
import Logo from '../common/logo'
import BackBtn from '../components/backBtn'
import Points from '../components/points'

const Header = ({ showBackBtn, totalPoints, uid }) => (
  <header className="header">
    {showBackBtn && <BackBtn uid={uid} />}
    <Logo size="md" />
    <ul className="account-icons">
      <li>
        <Points
          points={totalPoints}
          variant="sm"
          classes="account-badge center-text"
        />
      </li>
    </ul>
  </header>
)

export default Header

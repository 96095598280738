import React from 'react'
import { Link } from 'react-router-dom'

const TimelineButton = ({ label, path }) => (
  <Link to={path} className="btn center-text btn-primary">
    {label}
  </Link>
)

export default TimelineButton

import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import History from './common/history'
import Data from './data/customerLoyalty'
import { AnimatedSwitch } from 'react-router-transition'
import './assets/styles/main.scss'
import Home from './pages/home'
import Holding from './pages/holding'
import Provenance from './pages/provenance/home'
import CustomerLoyalty from './pages/customerLoyalty/home'
import CustomerLoyaltyExperiences from './pages/customerLoyalty/experiences'
import CustomerLoyaltyExperience from './pages/customerLoyalty/experience'
import InstantWin from './pages/instantWin/home'
import InstantWinForm from './pages/instantWin/form'
import InstantWinWin from './pages/instantWin/win'
import InstantWinLose from './pages/instantWin/lose'

class App extends Component {
  state = {
    isLoaded: false,
    totalPoints: 30,
    transitionDirection: 'left',
    experiences: Data.experiences.items,
    pointsRedeemed: false,
  }

  componentDidMount() {
    this.unlisten = History.listen((location, action) => {
      if (History.location.state !== undefined) {
        if (History.location.state.ref === 'back') {
          this.setState({
            transitionDirection: 'right',
          })
        } else {
          this.setState({
            transitionDirection: 'left',
          })
        }
      }
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  handleUpdatedTotalPoints(points) {
    this.setState({
      totalPoints: points,
    })
  }

  handleRedeemExperience(experience) {
    const { experiences } = { ...this.state }
    let exp = experiences.filter(f => f.slug === experience.slug)[0]
    exp.redeemed = true
    this.setState({
      experiences,
      pointsRedeemed: true,
    })
  }

  render() {
    const {
      experiences,
      totalPoints,
      transitionDirection,
      pointsRedeemed,
    } = this.state

    // Change page change transition based on level so experiences slide right to left
    let offsetEnter = 100
    let offsetLeave = -100

    if (transitionDirection === 'right') {
      offsetEnter = -100
      offsetLeave = 100
    } else {
      offsetEnter = 100
      offsetLeave = -100
    }

    return (
      <>
        <AnimatedSwitch
          atEnter={{ offset: offsetEnter }}
          atLeave={{ offset: offsetLeave }}
          atActive={{ offset: 0 }}
          className="route-wrapper"
          mapStyles={styles => ({
            transform: `translateX(${styles.offset}%)`,
          })}
        >
          <Route
            exact
            path="/provenance"
            render={props => (
              <Provenance
                totalPoints={totalPoints}
                pointsRedeemed={pointsRedeemed}
              />
            )}
          />
          <Route exact path="/customer-loyalty" component={CustomerLoyalty} />
          <Route
            exact
            path="/customer-loyalty/experiences"
            render={props => (
              <CustomerLoyaltyExperiences
                onUpdateTotalPoints={this.handleUpdatedTotalPoints.bind(this)}
                totalPoints={totalPoints}
                experiences={experiences}
                pointsRedeemed={pointsRedeemed}
              />
            )}
          />
          <Route
            exact
            path="/customer-loyalty/experience/:slug"
            render={props => (
              <CustomerLoyaltyExperience
                onRedeemExperience={this.handleRedeemExperience.bind(this)}
                onUpdateTotalPoints={this.handleUpdatedTotalPoints.bind(this)}
                totalPoints={totalPoints}
                experiences={experiences}
                pointsRedeemed={pointsRedeemed}
              />
            )}
          />
          <Route exact path="/instant-win" component={InstantWin} />
          <Route exact path="/instant-win/form" component={InstantWinForm} />
          <Route exact path="/instant-win/win" component={InstantWinWin} />
          <Route exact path="/instant-win/lose" component={InstantWinLose} />
          <Route exact path="/home" component={Home} />
          <Route component={Holding} />
        </AnimatedSwitch>
      </>
    )
  }
}

export default App

import React from 'react'

const MenuBtn = ({ open, handleClick, totalPoints, pointsRedeemed }) => {
  return (
    <div
      onClick={() => handleClick(open)}
      className={`menu-btn ${open ? 'menu-open' : 'menu-closed'} ${
        (open && totalPoints > 30) || (open && pointsRedeemed)
          ? ''
          : 'with-points'
      }`}
    ></div>
  )
}

export default MenuBtn

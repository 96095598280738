import React, { Component } from 'react'
import Data from '../../data/provenance'
import Menu from '../../common/menu'
import Header from '../../common/header'
import Footer from '../../common/footer'
import Loading from '../../pages/provenance/loading'
import Timeline from '../../pages/provenance/timeline'

class ProvenanceHome extends Component {
  state = {
    isLoaded: false,
  }

  componentDidMount() {
    window.scroll(0, 0)
    // Force reload on page to get the svg animating [?reload=true]
    const params = window.location.search.split('&')
    if (params.length > 1) {
      window.location.href = `/provenance${params[0]}`
    }
    setTimeout(f => {
      this.setState({ isLoaded: true })
    }, 1800)
  }

  render() {
    const { isLoaded } = this.state
    const { totalPoints, pointsRedeemed } = this.props
    const uid = window.location.search

    return (
      <>
        <Menu
          uid={uid}
          page={0}
          totalPoints={totalPoints}
          pointsRedeemed={pointsRedeemed}
        />
        <Header totalPoints={totalPoints} />
        <div className="center-text page-title">Bottle Story</div>
        <Loading data={Data.loading} />
        {isLoaded && (
          <>
            <Timeline data={Data.timeline} />
            <Footer />
          </>
        )}
      </>
    )
  }
}

export default ProvenanceHome

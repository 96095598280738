import React from 'react'
import Points from '../components/points'
import PinInput from '../components/pinInput'

const ModalRedeem = ({
  redeemFormatCount,
  data,
  onSubmit,
  onClose,
  onChange,
  isValid,
  isError,
  isRedeemPoints,
  newPoints,
}) => {
  const successMessage = data.success.replace('xxx', newPoints)

  return (
    <div className="modal-wrapper">
      <div className="modal animated slideInDown">
        <div className="center-text">
          {!isRedeemPoints ? (
            <>
              <h1 className="white-text">{data.title}</h1>
              <form onSubmit={onSubmit}>
                <PinInput
                  onChange={onChange}
                  isValid={isValid}
                  isError={isError}
                  data={data}
                  count={redeemFormatCount}
                />
              </form>
            </>
          ) : (
            <div className="container-sm">
              <Points
                animateText={true}
                showPlus={true}
                points={newPoints}
                variant="lg"
                classes="animated fadeInUp center-text"
              />
              <p className="animated fadeInUp delay-1s">{successMessage}</p>
            </div>
          )}
        </div>
      </div>
      <div onClick={onClose} className="backdrop" />
    </div>
  )
}

export default ModalRedeem

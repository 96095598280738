import { Component } from 'react'
import History from '../../common/history'

class CustomLoyaltyHome extends Component {
  componentDidMount() {
    window.scroll(0, 0)

    const urlParams = new URLSearchParams(window.location.search)
    const uid = urlParams.has('uid') ? urlParams.get('uid') : null

    setTimeout(f => {
      History.push(`/customer-loyalty/experiences?uid=${uid}`)
    }, 200)
  }

  render() {
    return null
  }
}

export default CustomLoyaltyHome
